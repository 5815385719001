.nav{

    &-wrapper{
        width: auto;
        height: 100px;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        background-color: #eee;
        z-index: 100;

       
    }

    &-container{
        padding: 0 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1300px;
        margin: auto;

        @media screen and (max-width: 1150px) {
            max-width: 1200px;
        }

        @media screen and (max-width: 600px) {
            padding: 0px 20px;
        }
    }

    &-logo{
        width: 80px;
        transition: all .2s ease-out;

        &:hover{
            cursor: pointer;
            background-color: #89d63c9e;
            transition: all .2s ease-in;
            border-radius: 8px;
        }
        &-link{
            text-decoration: none;
            color: inherit;
            position: relative;
        }
    }
    &-links{

        &-ul{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            list-style: none;
            gap: 15px;

            @media screen and (max-width: 830px) {
                flex-direction: column;
                gap: 25px;
                align-items: end;
            }
        }
        &-li{
            border-radius: 8px;
            transition: all .2s ease-in-out;
            width: fit-content;
            height: fit-content;
            padding: 5px 15px;

            @media screen and (max-width: 830px) {
                padding: 0px;
            }
            

            &-link{
                text-decoration: none;
                color: #000;
                font-size: 28px;
                border-radius: 8px;
                transition: all .2s ease-in-out;
                padding: 5px 15px;

                &-icon{
                    padding: 10px 10px 5px;
                }

                &:hover{
                    transition: all .2s ease-in;
                    background-color: #2B3542;
                    color: #fff;
                    cursor: pointer;
                    box-shadow: 0px 0px 3px 0px #313131;
                }
                &-active{
                    text-decoration: none;
                    color: #000;
                    font-size: 28px;
                    padding: 5px 15px;
                    border-radius: 9px;
                    transition: all .2s ease;
                    background-color: transparent;
                    border: 1px solid #454545; 
                    cursor: default;
                }
            }

            
        }
        &-icons{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            list-style: none;
            gap: 23px;
            margin-left: 8px;

            @media screen and (max-width: 830px) {
                flex-direction: column;
                gap: 25px;
            }
            
            &-li{
                height: auto;
                font-size: 26px;
                line-height: 27px;
            }
            &-li a,
            &-li a i{
                font-size: 26px;
                line-height: 27px;
                transition: all .2s ease-in-out;
            }
            &-li a{
    
                &:hover > i {
                    transition: all .2s ease-in-out;
                    color: #fff;
                    box-shadow: 0px 0px 3px 0px #313131;
                }
            }
        }
        
    }
}
#mobile-overlay{
    display: none;
}
#mobile-sidebar-active{
    display: none;

    &:checked ~ .mobile-nav-container{
        right: 0;
    }
    &:checked ~ #mobile-overlay{
        display: block;
        background-color: transparent;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;

        @media screen and (min-width: 830px) {
            display: none;
        }
    }
    
}
.mobile{

    &-nav{

        &-open{
            display: none;
            @media screen and (max-width: 830px) {
                display: block;
            }
        }
        &-icons{}
        &-container{
            height: 100%;
            width: auto;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            transition: right .2s ease-in-out;

            @media screen and (max-width: 830px) {
                
                flex-direction: column;
                position: fixed;
                top: 0;
                right: -100%;
                z-index: 10;
                width: 300px;
                background-color: #fff;
                border-radius: 8px;
                justify-content: normal;
                padding-right: 12px;
                align-items: end;
                gap: 40px;
                padding-top: 25px;
                box-shadow: -20px 8px 33px rgba(0, 0, 0, 0.41);
            }
        }
        &-close{
            display: none;
            
            @media screen and (max-width: 830px) {
                display: block;
                transform: translateX(-12px);
            }
        }
        &-links{

            &-ul{}
            &-icons{

                &-li{

                    &-link{

                    }
                }
            }
        }
    }
}

.fa{
    &-linkedin{
        color: #0077b5;
    }
    &-instagram{
        color: #E1306C;
    }
    &-download{
        color: #000;
    }
}

.nav-links-li > .nav-links-li-link-active {
    cursor: default;
}


@media only screen and (max-width: 800px){
    .nav-links-ul{

    }
}

