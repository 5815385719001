
.resumePage{

    &-container{
        width: 100%;
		overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 48px 0;
        margin: auto;
    }
    &-header{
        color: #000;
        margin-bottom: 0px;
        font-size: 68px;
        font-family: "Jersey 20", sans-serif;
        font-weight: 400;
        font-style: normal;
    }
}