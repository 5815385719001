
.music{

    &-wrapper{
        width: auto;
        height: auto;
        position: relative;
        padding: 0 50px 50px;
        margin: auto;
        max-width: 1300px;

        @media screen and (max-width: 600px) {
            padding: 0 20px;
        }
    }

    &-container{
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        row-gap: 40px;
        column-gap: 40px;

        @media screen and (max-width: 750px) {
            gap: 20px;
        }
    }

    &-card{
        width: auto;
        min-width: 300px;
        
        @media screen and (max-width: 750px) {
            width: 100%;
        }
    }   
}