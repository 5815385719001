.contact{
    &-container{
        width: 100%;
        height: 180px;
        background-color: #73B7CE;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        column-gap: 24px;
        row-gap: 24px;
        box-shadow: 0px 4px 7px 0px #313131;
        padding: 0 32px;

        @media screen and (max-width: 1100px) {
            flex-direction: column;
            height: auto;
            row-gap: 12px;
            padding: 20px 32px;
            width: 100%;
        }

    }
    &-div{
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 100%;
    }
    &-label{
        color: #fff;
        font-size: 28px;
        font-weight: normal;
        margin-bottom: 8px;
        letter-spacing: 1px;
    }
    &-email{
        margin-top: 8px;
    }
    &-input{
        height: 35px;
        border-radius: 8px;
        border: none;
        padding: 0 8px;
    }
    &-textarea{
        height: 106px;
        border-radius: 8px;
        border: none;
        padding: 4px 8px;
    }
    &-submit{
        height: 106px;
        margin-top: 30px;
        width: auto;
        border-radius: 8px;
        border: none;
        padding: 4px 12px;
        transition: all .2s ease-in;
        background-color: #CEBEA8;
        color: #fff;
        font-size: 28px;
        font-weight: normal;
        letter-spacing: 1px;

        @media screen and (max-width: 1100px) {
            height: 40px;
            margin: 0;
        }


        &:hover{
            background-color: #8AD63C;
            color: #fff;
            box-shadow: 0 3px 8px #2d2d2d;
            transition: all .2s ease-out;
            cursor: pointer;
        }
        &:active{
            background-color: #fff;
            color: #000;
            box-shadow: 0 3px 8px #2d2d2d inset;
            cursor: pointer;
        }
    }
}

.swal2-title{
    font-size: 32px;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: 34px;
    color: #000;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm){
    background-color: #8AD63C;
    font-size: 24px;
    line-height: 26px;
}