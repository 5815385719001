.banner{

    &-wrapper{
        width: 100%;
        max-width: 1300px;
        padding: 0 0 0 50px;
        margin: auto;
        height: 500px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // background: linear-gradient(0deg, #000, #526E8F, #DAD5CF);
        // background-size: 300% 300%;
	    // background-position: center;
        // animation: gradient 7s ease infinite;

        @media screen and (max-width: 600px) {
            height: auto;
            padding: 52px 20px 0;
        }
    }
    &-left{
        min-width: 300px;
        width: 40%;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    &-title{
        font-size: 100px;
        margin-bottom: 15px; 
        font-family: "Jersey 20", sans-serif;
        font-weight: 400;
        font-style: normal;
        text-align: start;
        color: #000;

        @media screen and (max-width: 600px) {
            font-size: 75px;
        }
        
    }
    &-subText{
        font-family: "Inria Sans", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        line-height: 1.7rem;
        text-align: start;
        margin-bottom: 12px;
    }
    &-desc{
        font-family: "Inria Serif", serif;
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
        line-height: 1.7rem;
        text-align: start;
        min-width: 300px;

        @media screen and (max-width: 600px) {
            margin-top: 0;
        }
    }
    &-div{
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 25px;
        margin-top: 22px;
    }
    &-btn{
        font-family: "Jersey 10", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        text-decoration: none;
        color: #fff;
        padding: 8px 16px;
        border-radius: 8px;
        box-shadow: 0px 0px 3px 0px #313131;
        transition: all .2s ease-in;

        &:hover{
            cursor: pointer;
            transition: all .2s ease-out;
            box-shadow: 0px 4px 7px 0px #313131;
        }
    }

    &-right{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        user-select: none;

        @media screen and (max-width: 600px) {
            display: none;
        }
    }
    &-logo{
        width: auto;
        margin-left: auto;
        margin-top: 80px;
        user-select: none;

        @media screen and (max-width: 740px) {
            width: 300px;
            padding-right: 10px;
        }
        @media screen and (max-width: 650px) {
            width: 260px;
            padding-right: 15px;
        }
    }
}

.btn1{
    background-color: #CEBEA8;
}
.btn2{
    background-color: #73B7CE;
}

@keyframes gradient {
    0% {
        background-position: 20% 20%;
    }
    25% {
        background-position: 50% 20%;
    }
    50% {
        background-position: 50% 50%;
    }
    50% {
        background-position: 20% 50%;
    }
    100% {
        background-position: 20% 20%;
    }
}


  