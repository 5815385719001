

.singleProjectPage{

    &-container{
        width: 100%;
		overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 50px 0;
        max-width: 1800px;
        margin: auto;
    }
}