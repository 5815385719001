.photoViewer{
    &-wrapper{
        width: auto;
    }
}

.pv{

    &-thumb_link{
        height: 150px;
        width: 150px;
        border-radius: 8px;
        border: none;
    }
    &-thumb_img{
        height: 100%;
        width: 100%;
        transition: opacity .2s ease;
        border-radius: 8px;
    }
    &-thumb_img:hover{
        opacity: .5;
        cursor: pointer;
        transition: opacity .2s ease;
    }
    &-image{
        width: 100%;
        height: 100%;
        position: relative;
    }
}
.pv-thumb_img .active{
    opacity: .5 !important;
    cursor: default;
    user-select: none;
}

#pv{

    &-container{
        height: 400px;
        width: 600px;
        margin-bottom: 30px;
        background-color: rgb(223, 145, 145);
        border-radius: 8px;
        position: relative;
    }
    &-image{
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 8px;
    }
    &-thumbnails{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: auto;
        max-width: 600px;
    }
}

