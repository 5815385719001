
.resume{

    &-wrapper{
     width: 100%;
     height: auto;   
     padding: 0 50px;
     max-width: 1300px;
     margin: auto;  

     @media screen and (max-width: 600px) {
        padding: 0px 20px;
    }
    }
    &-header{
        color: #fff;
        margin-bottom: 40px;

        @media screen and (max-width: 600px) {
            font-size: 50px;
            line-height: 52px;
        }
    }
    &-container{
        width: 100%;
        height: auto;
        display: flex;
        margin: auto;
        padding: 40px 50px;
        gap: 30px;
        // background-color: rgba(226, 253, 255, 0.33);
        background-color: #73B7CE;
        border-radius: 8px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        transition: translate 250ms ease-in-out;

        @media screen and (max-width: 1330px) {
            flex-direction: column;
        }
    }
    
    &-left{
        text-align: start;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: 1330px) {
            text-align: center;
            align-items: center;
        }

        &-card{}
        &-title{
            margin-bottom: 15px;
            color: #fff;
        }
        &-profession,
        &-location{
            color: #fff;
        }
        &-contact,
        &-btn{
            text-decoration: none;
            color: #fff;
            width: fit-content;
            background-color: #4B8CC8;
            margin-top: 40px;
            padding: 10px 20px;
            border: none;
            border-radius: 8px;
            box-shadow: 0;
            transition: .2s all ease;

            &:hover,
            &:focus-visible{
                background-color: #526E8F;
                box-shadow: 0 3px 8px #2d2d2d;
                transition: .2s all ease;
                cursor: pointer;
            }
            &:active{
                background-color: #fff;
                color: #000;
                box-shadow: 0 3px 8px #2d2d2d inset;
                cursor: pointer;
            }
        }

    }

    &-right{
        padding: 20px 0px;
        height: 70vh;
        min-height: 400px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 30px;
        border: 1px solid #ebebeb17;
        border-radius: 8px;
        box-shadow: -4px -3px 20px 0px #282828 inset;
    }

    &-card{
        height: auto;
        width: 89%;
        margin: auto;
        padding: 20px;
        border: 1px solid #ebebeb17;
        background-color: aliceblue;
        border-radius: 8px;
        display: grid;
        grid-template-columns: 40% 60%;
        justify-content: space-around;
        align-items: center;
        text-align: start;

        @media screen and (max-width: 800px) {
            padding: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &-left{
            display: flex;
            justify-content: start;
            align-items: center;

            @media screen and (max-width: 800px) {
                justify-content: center;
                margin-bottom: 15px;
            }
        }
        &-right{

            @media screen and (max-width: 800px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        &-img{
            width: 80%;
            height: auto;
            border-radius: 8px;
        }

        &-title{
            margin-bottom: 10px;

            @media screen and (max-width: 800px) {
                text-align: center;
            }
        }

        &-info{
            display: flex;
            flex-direction: row;
            gap: 30px;
            justify-content: space-between;
            align-items: start;

            @media screen and (max-width: 800px) {
                flex-direction: column-reverse;
                gap: 5px;
                align-items: center;
            }

            &-left{
                text-align: start;
                color: #666;

                @media screen and (max-width: 800px) {
                    text-align: center;
                }
            }
            &-right{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                gap: 10px;
            }
        }
        &-icons{
            font-size: 30px;
            color: #000;
        }

        &-subtitle{

            &-length{
            }
        }
        &-desc{
            margin-top: 10px;

            @media screen and (max-width: 800px) {
                text-align: center;
                margin-top: 15px;
            }
        }

        &-link{
            height: fit-content;
            text-decoration: none;
            color: #fff;
            width: fit-content;
            background-color: #4B8CC8;
            margin-top: 15px;
            margin-left: auto;
            padding: 10px 20px;
            border: none;
            border-radius: 8px;
            box-shadow: 0;
            transition: .2s all ease;
            display: block;

            @media screen and (max-width: 800px) {
                margin-right: auto;
            }

            &:hover,
            &:focus-visible{
                background-color: #526E8F;
                box-shadow: 0 3px 8px #2d2d2d;
                transition: .2s all ease;
                cursor: pointer;
            }
            &:active{
                background-color: #fff;
                color: #000;
                box-shadow: 0 3px 8px #2d2d2d inset;
                cursor: pointer;
            }
        }
    }
}

.fa{

    &-vuejs{
        color: #42b883;
    }
    &-html5{
        color: #f06529;
    }
    &-sass{
        color: #cc6699;
    }
    &-react{
        color: #61DBFB;
    }
    &-shopify{
        color: #96bf48;
    }
    &-wordpress{
        color: #008080;
    }
    &-css3-alt{
        color: #2965f1;
    }
    &-js{
        color: #f0db4f;
    }
    &-opencart{
        color: #229ac8;
    }
    &-mailchimp{
        color: #000;
        background-color: #FFE01B;
        border-radius: 50%;
        padding: 3px;
    }
}