*{
  margin: 0;
  padding: 0;
  font-style: normal;
  box-sizing: border-box;
}

div::-webkit-scrollbar,
div::-webkit-scrollbar-track,
div::-webkit-scrollbar-track-piece {
  background: none;
  width: 12px;
}

div::-webkit-scrollbar-thumb {
  background-color: rgba(226, 253, 255, 0.33);
  border-radius: 8px;
  border: 2px solid #fff;
  transition: all .5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
div::-webkit-scrollbar-thumb:hover {
  background-color: rgba(226, 253, 255, 0.6);
  transition: all .5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

html{
  height: 100%;
}

body{
  min-height: 100%;
  display: block;
}


h1{
  font-size: 68px;
  line-height: 68px;
  font-family: "Jersey 20", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .7px;
}
h2{
  font-size: 60px;
  line-height: 64px;
  font-family: "Jersey 20", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .7px;
}
h3{
  font-size: 45px;
  line-height: 48px;
  font-family: "Jersey 20", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .7px;
}
h4{
  font-size: 30px;
  line-height: 34px;
  font-family: "Jersey 20", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .7px;
}
h5{
  font-size: 20px;
  line-height: 22px;
  font-family: "Jersey 20", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .7px;
}
h6{
  font-size: 14px;
  line-height: 18px;
  font-family: "Jersey 20", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .7px;
}
p, a, input,textarea{
  font-size: 19px;
  line-height: 21px;
  font-family: "Inria Serif", serif;
  font-weight: 400;
  font-style: normal;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 19px;
  }
}

button, label{
  font-size: 19px;
  line-height: 21px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 19px;
  }
}

label,
li,
li a {
  font-family: "Jersey 10", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  letter-spacing: .7px;
}

button{
  font-family: "Jersey 20", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .7px;
}

a{
  letter-spacing: .7px;
}

#root{
  text-align: center;
  padding: 0px;
}


.animated-bkg-div{
  background: radial-gradient(#2B3542, #526E8F, #DAD5CF, #DAD5CF);
  background-size: 300% 300%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  animation: gradient 7s ease infinite;
}

.site-container{}

@keyframes gradient {
0% {
  background-position: 20% 20%;
}
25% {
  background-position: 50% 20%;
}
50% {
  background-position: 50% 50%;
}
50% {
  background-position: 20% 50%;
}
100% {
  background-position: 20% 20%;
}
}
