
.bio{

    &-wrapper{
        width: 70%;
        height: auto;
        margin: auto;
        padding: 45px 3%;
        background-color: rgba(226, 253, 255, 0.33);
        border-radius: 8px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);  
    }
}

.short-bio{

    &-wrapper{
        width: 100%;
        height: auto;
        padding: 0 50px;
        margin: auto;
        max-width: 1300px;
        display: flex;
        flex-direction: column;   
        
        @media screen and (max-width: 600px) {
            padding: 0 20px;
        }
    }
    &-container{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 38px 3% 24px;
        gap: 20px;
        // background-color: rgba(226, 253, 255, 0.33);
        background-color: #89d63ca2;
        border-radius: 8px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
    &-title{
        text-align: start;
        color: #fff;

        @media screen and (max-width: 600px){
            font-size: 45px;
            line-height: 48px;
        }
    }

    &-text{
        text-align: start;
        font-size: 25px;
        line-height: 28px;
        color: #000;

        @media screen and (max-width: 600px){
            font-size: 20px;
            line-height: 22px;
        }
    }
    &-author{
        text-align: end;
        font-size: 26px;
        color: #fff;
        font-weight: bold;

        @media screen and (max-width: 600px){
            font-size: 18px;
            line-height: 22px;
        }
    }
}