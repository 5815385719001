.projects{

    &-wrapper{
        width: auto;
        height: 600px;
        position: relative;
        display: flex;
        justify-content: space-evenly;
        padding: 0 10% 50px;
    }

    &-container{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: hidden;
    }
    &-nav{
        color: white;
        display: flex;
        gap: 5px;
        justify-content: space-between;
        align-items: center;  
        position: absolute;    
        bottom: 9%;
        padding: 10px;
        border-radius: 8px;

        background-color: rgba(226, 253, 255, 0.33);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);

        &-btn{
            background-color: transparent;
            border: none;
        }

        &-icon{
            color: #fff;
            border-radius: 50%;

            &:hover,
            &:focus-visible{
                transition: all .2s ease;
                background-color: #fff;
                cursor: pointer;
            }

            &-selected{
                color: #fff;
                border-radius: 50%;
                background-color: #526E8F;
                
                &:hover,
                &:focus-visible{
                    cursor: default;
                }
            }
        }
    }
    &-arrow{
        color: white;
        position: absolute; 
        bottom: 50%;
        font-size: 55px;
        transition: .2s color ease;

        &-left{ 
            left: 5%;
        }
        &-right{
            right: 5%;
        }
        &:hover,
        &:focus-visible{
            color: #d2d2d2;
            transition: .2s color ease;
            cursor: pointer;
        }
    }
}

.project{

    &-card{
        width: 100%;
        height: 83%;
        display: flex;
        flex-direction: row;
        padding: 25px 3%;
        gap: 50px;

        background-color: rgba(226, 253, 255, 0.33);
        border-radius: 8px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        transition: translate 250ms ease-in-out;
        

        &-top{
            width: 55%;
            height: 100%;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            box-shadow: 0 4px 20px #2d2d2d;
        }
        &-img{
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
        &-bottom{
            color: #fff;
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            text-align: start;
        }
        &-icons{
            font-size: 30px;
        }
    }
    &-name{
        
    }
    &-icons{
        display: flex;
        width: fit-content;
        padding: 5px 10px;
        gap: 15px;
        background-color: rgba(240, 248, 255, 0.51);
        border-radius: 8px;
    }
    &-desc{
        
    }
    &-btns{
        display: flex;
        gap: 30px;
    }
    &-viewBtn{
        display: inline-block;
        color: #fff;
        width: fit-content;
        background-color: #4B8CC8;
        padding: 10px 20px;
        border: none;
        border-radius: 8px;
        box-shadow: 0;
        transition: .2s all ease;
        text-decoration: none;

        &:hover,
        &:focus-visible{
            background-color: #526E8F;
            box-shadow: 0 3px 8px #2d2d2d;
            transition: .2s all ease;
            cursor: pointer;
        }
        &:active{
            background-color: #fff;
            color: #000;
            box-shadow: 0 3px 8px #2d2d2d inset;
            cursor: pointer;
        }
    }
    &-infoBtn{
        display: inline-block;
        color: #fff;
        width: fit-content;
        background-color: #4B8CC8;
        padding: 10px 20px;
        border: none;
        border-radius: 8px;
        box-shadow: 0;
        transition: .2s all ease;
        text-decoration: none;

        &:hover,
        &:focus-visible{
            background-color: #526E8F;
            box-shadow: 0 3px 8px #2d2d2d;
            transition: .2s all ease;
            cursor: pointer;
        }
        &:active{
            background-color: #fff;
            color: #000;
            box-shadow: 0 3px 8px #2d2d2d inset;
            cursor: pointer;
        }
    }
}

.skip-link{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    border: 0;
    clip: rect(0,0, 0,0);
}
.skip-link:focus-visible{
    top: 0px;
    left: 0;
    border: 1px solid #000;
    background-color: #fff;
    padding: .5rem;
    width: auto;
    height: auto;
    margin: 0;
    clip: unset;
    text-decoration: none;
    color: #000;
    z-index: 100;
}

@media not (prefers-reduced-motion){

}

// @media not (prefers-reduced-motion){
//     .job-card{
//         transition: translate 250ms ease-in-out;
//     }
// }