.footer{

    &-wrapper{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: #eeeeee;
    }
    &-top{
        width: 100%;
        max-width: 1300px;
        height: auto;
        display: flex;
        justify-content: space-between;
        gap: 52px;
        padding: 50px 50px 10px;

        @media screen and (max-width: 670px) {
            flex-direction: column-reverse;
        }
        @media screen and (max-width: 600px) {
            padding: 20px 20px 10px;
        }
    }
    &-left{
        width: auto;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;

    }
    &-name{
        color: #000;
        font-size: 65px;
        text-decoration: underline;
        margin-bottom: 18px;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        &:hover{
            cursor: default;
        }
    }
    &-links{

        &-ul{
            width: auto;
            max-height: 200px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 20px;
            text-align: start;
            list-style: none;
            transform: translateX(-16px);

            @media only screen and (max-width: 670px){
                transform: translateX(0px);
             }
        }
        &-li{
            padding: 5px 15px;
            border-radius: 8px;
            transition: all .2s ease;

            &:hover{
                transition: all .2s ease;
                background-color: #DAD5CF;
                cursor: pointer;
            }
        }
    }
    &-right{
        width: 57%;
        min-width: 741px;
        height: fit-content;
        border-radius: 8px;

        @media screen and (max-width: 1100px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 300px; 
            width: 100%;
        }
    }
    &-bottom{
        width: 100%;
        height: 50px;
        padding: 10px 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        @media only screen and (max-width: 600px){
           padding: 12px;
        }
    }
    &-copyright{
        font-size: 18px;
        line-height: 24px;
    }
}


@media only screen and (max-width: 670px){
    .footer-wrapper{
        padding-top: 20px;
    }
    .footer-links-ul{
        max-height: auto;
        justify-content: center;
        flex-direction: row;
    }
}
