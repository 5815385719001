

.webs{

    &-wrapper{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 28px;
        padding: 0 50px 50px;
        max-width: 1300px;
        margin: auto;

        @media screen and (max-width: 600px) {
            padding: 0 20px 40px;
        }
    }
    &-label{

    }
    &-container{
        margin: auto;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 48px;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 960px) {
            gap: 24px;
        }
    }
    &-card{
        position: relative;
        width: 252px;
        height: 252px;
        background-color: #EBECEF;
        border: 5px solid #A8C1C4;
        border-radius: 12px;
        transition: all .2s ease-out;

        @media screen and (max-width: 960px) {
            width: 200px;
            height: 200px;
        }
        @media screen and (max-width: 760px) {
            width: 252px;
            height: 252px;
        }
        @media screen and (max-width: 630px) {
            width: 200px;
            height: 200px;
        }
        @media screen and (max-width: 480px) {
            width: 100%;
        }

        &:hover,
        &:focus-visible{
            border-color: #8AD63C;
            box-shadow: 0 3px 8px #2d2d2d;
            transition: all .2s ease-out;
            cursor: pointer;
        }
        &_link{

            &:hover{
                cursor: pointer;
            }
        }
        &_image{
            width: 100%;
            height: 100%;
            border-radius: 8px;

            @media screen and (max-width: 480px) {
                width: auto;
            }
        }
        &_title{
            color: #000;
            font-size: 28px;
            line-height: 29px;
            margin: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 960px) {
                font-size: 22px;
            }

        }
    }
}