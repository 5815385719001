.projectsPage{

    &-container{
        width: 100%;
		overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 50px 0;
        max-width: 1800px;
        margin: auto;
    }

    &-header{
        color: #fff;
        margin: 50px 0 20px;

        @media screen and (max-width: 600px) {
            font-size: 50px;
            line-height: 52px;
        }
    }

    &-subtitle{
        color: #fff;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 50px;
    }
}