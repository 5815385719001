
*:focus-visible{
	outline: auto;
}

.home{

    &-container{
        width: 100%;
		overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 50px;

        @media screen and (max-width: 600px) {
            padding: 40px 20px;
        }
    }
    &-card{
        max-width: 1200px;
        margin: auto;
        border-radius: 8px;
        padding: 48px 32px;
        background-color: #89d63ca2;
    }
    &-title{
        font-size: 68px;
        margin-bottom: 15px; 
        font-family: "Jersey 20", sans-serif;
        font-weight: 400;
        font-style: normal;
        text-align: start;

        @media screen and (max-width: 600px) {
            font-size: 52px;
        }
    }
    &-text{
        font-family: "Inria Serif", serif;
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
        line-height: 1.7rem;
        text-align: start;
    }
    &-div{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
    }
    &-btn{
        font-family: "Jersey 10", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        text-decoration: none;
        color: #fff;
        padding: 8px 16px;
        border-radius: 8px;
        box-shadow: 0px 0px 3px 0px #313131;
        transition: all .2s ease-in;

        @media screen and (max-width: 600px) {
            font-size: 32px;
        }
        @media screen and (max-width: 400px) {
            font-size: 24px;
        }

        &:hover{
            cursor: pointer;
            transition: all .2s ease-out;
            box-shadow: 0px 4px 7px 0px #313131;
        }
    }
}

.margb{
    margin-bottom: 48px;
}
.btn1{
    background-color: #CEBEA8;
}
.btn2{
    background-color: #73B7CE;
}
.btn3{
    background-color: #8AD63C;
}