.musicPage{

    &-container{
        width: 100%;
		overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 50px 0;
        max-width: 1800px;
        margin: auto;
    }

    &-header{
        color: #000;

        @media screen and (max-width: 600px) {
            font-size: 50px;
            line-height: 52px;
        }
    }
}