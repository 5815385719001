.nopage{

    &-wrapper{
        width: 100%;
        height: 100%;
        min-height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}