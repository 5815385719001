.quote{

    &-container{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ebebeb;
        padding: 0px 10.5%;
        gap: 15px;

        @media screen and (max-width: 700px) {
            flex-direction: column;
        }
    }

    &-text{
        color: #3c3c3c;
        font-size: 28px;
        line-height: 31px;
        letter-spacing: 2px;
        font-style: italic;
        padding: 15px 0;

        @media screen and (max-width: 700px) {
            padding: 15px 0 10px;
        }
        @media screen and (max-width: 500px) {
            padding: 15px 0 0px;
            font-size: 20px;
            line-height: 22px;
        }
    }
    &-text::before,
    &-text::after{
        color: #526E8F;
    }
    
    &-author{
        color: #3c3c3c;
        font-size: 28px;
        line-height: 31px;
        letter-spacing: 2px;
        font-style: italic;
        padding: 15px 0;

        @media screen and (max-width: 700px) {
            padding: 0 0 15px;
        }
        @media screen and (max-width: 500px) {
            font-size: 20px;
            line-height: 22px;
        }
    }
   
}