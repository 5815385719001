
.singleProject{

    &-wrapper{
        width: 100%;
        height: auto;
        padding: 0 10%;
    }
    &-container{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 45px 3%;
        gap: 30px;
        background-color: rgba(226, 253, 255, 0.33);
        border-radius: 8px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        transition: translate 250ms ease-in-out;

        @media screen and (max-width: 1330px) {
            flex-direction: column;
        }

        @media screen and (max-width: 600px) {
            width: 90%;
        }
    }
    &-logo{
        width: 150px;
        height: 150px;
        border-radius: 8px;
        background-color: antiquewhite;
        margin: auto;
    }
    &-top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: auto;
        gap: 30px;

        &-images{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 60%;
            gap: 30px;

            &-highlight{
                width: 100%;
                height: 400px;
                border-radius: 8px;
                background-color: antiquewhite;
            }
            &-options{
                width: 100%;
                height: 150px;
                background-color: rgba(240, 248, 255, 0.51);
                border-radius: 8px;
            }
        }
        &-text{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 40%;
            gap: 30px;

            &-title{
                color: #fff;
                width: 100%;
                text-align: start;
            }
            &-desc{
                width: 100%;
                text-align: start;
            }
        }
    }
    &-bottom{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 30px;

        &-title{
            width: 40%;
            text-align: start;
        }
        &-desc{
            width: 40%;
            text-align: start;
        }
        &-image{
            width: 60%;
            height: 400px;
            border-radius: 8px;
            background-color: antiquewhite;
        }
    }
}